<script>
import api from '@/services/secureHttps';
export default {

    data() {
        return {
            orgName: "",
            websiteUrl: "",
            errorMessages: [],
            setupComplete: false,
            clientScript: null,
        }
    },

    methods: {

        async onSubmit() {
            var requestBody = {};
            var website = {
                websiteUrl: this.websiteUrl
            };
            var org = {
                name: this.orgName
            };

            requestBody.website = website;
            requestBody.organisation = org;

            console.log(requestBody);

            api.associateOrgAndWebsiteWithUser(requestBody)
                .then((response) => {
                    console.log(response);
                    this.errorMessages = [];
                    this.setupComplete = true;
                    this.clientScript = response.data.websiteInfo.managerScript;
                })
                .catch(error => {
                    console.log("error", error);
                    let responseData = error.response.data;

                    if (typeof responseData.errors !== 'undefined') {
                        this.errorMessages = [];

                        for (let i = 0; i < responseData.errors.length; i++) {
                            var errorGroup = responseData.errors[i];

                            for (let j = 0; j < errorGroup.description.length; j++) {
                                this.errorMessages.push(errorGroup.description[j]);
                            }
                        }
                    }
                    else {
                        this.errorMessages = ['Unkown error. Sorry we could not create an account at this time. Our tech team will look at the problem. Please try again later.']
                    }
                });
        },

        onContinue() {
            this.$router.push({ name: 'default' });
        }
    }

}
</script>
    
<template>
    <div class="auth-page-wrapper pt-5">
        <!-- auth page bg -->
        <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
            <div class="bg-overlay"></div>

            <div class="shape">

                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 1440 120">
                    <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
                </svg>
            </div>
            <Particles id="tsparticles" :options="{
                particles: {
                    number: {
                        value: 90,
                        density: {
                            enable: true,
                            value_area: 800,
                        },
                    },
                    color: {
                        value: '#ffffff',
                    },
                    shape: {
                        type: 'circle',
                        stroke: {
                            width: 0,
                            color: '#000000',
                        },
                        polygon: {
                            nb_sides: 5,
                        },
                        image: {
                            src: 'img/github.svg',
                            width: 100,
                            height: 100,
                        },
                    },
                    opacity: {
                        value: 0.8,
                        random: true,
                        anim: {
                            enable: true,
                            speed: 1,
                            opacity_min: 0,
                            sync: false,
                        },
                    },
                    size: {
                        value: 4,
                        random: true,
                        anim: {
                            enable: false,
                            speed: 4,
                            size_min: 0.2,
                            sync: false,
                        },
                    },
                    line_linked: {
                        enable: false,
                        distance: 150,
                        color: '#ffffff',
                        opacity: 0.4,
                        width: 1,
                    },
                    move: {
                        enable: true,
                        speed: 2,
                        direction: 'none',
                        random: false,
                        straight: false,
                        out_mode: 'out',
                        attract: {
                            enable: false,
                            rotateX: 600,
                            rotateY: 1200,
                        },
                    },
                },
                interactivity: {
                    detect_on: 'canvas',
                    events: {
                        onhover: {
                            enable: true,
                            mode: 'bubble',
                        },
                        onclick: {
                            enable: true,
                            mode: 'repulse',
                        },
                        resize: true,
                    },
                    modes: {
                        grab: {
                            distance: 400,
                            line_linked: {
                                opacity: 1,
                            },
                        },
                        bubble: {
                            distance: 400,
                            size: 4,
                            duration: 2,
                            opacity: 0.8,
                            speed: 3,
                        },
                        repulse: {
                            distance: 200,
                        },
                        push: {
                            particles_nb: 4,
                        },
                        remove: {
                            particles_nb: 2,
                        },
                    },
                },
                retina_detect: true,
                config_demo: {
                    hide_card: false,
                    background_color: '#b61924',
                    background_image: '',
                    background_position: '50% 50%',
                    background_repeat: 'no-repeat',
                    background_size: 'cover',
                },
            }" />
        </div>

        <!-- auth page content -->
        <div class="auth-page-content">
            <div v-if="setupComplete === false" class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center mt-sm-5 mb-4 text-white-50">
                            <div>
                                <router-link to="/" class="d-inline-block auth-logo">
                                    <img src="@/assets/images/logo-light.png" alt="" height="20">
                                </router-link>
                            </div>
                            <p class="mt-3 fs-15 fw-medium">Low code SEO</p>
                        </div>
                    </div>
                </div>
                <!-- end row -->

                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6 col-xl-5">
                        <div class="card mt-4">

                            <div class="card-body p-4">
                                <div class="text-center mt-2">
                                    <h5 class="text-primary">Complete Account Setup</h5>
                                </div>
                                <div class="p-2 mt-4">
                                    <form class="needs-validation" novalidate>

                                        <div class="mb-3">
                                            <label for="orgName" class="form-label">Organisation</label>
                                            <p class=" text-break text-body">If you are not part of an organisation, you
                                                can use your website name.</p>
                                          
                                            <input v-model="orgName" type="text" class="form-control" id="orgName"
                                                placeholder="Organisation/Website name " required>
                                            <div class="invalid-feedback">
                                                Please enter organisation/website.
                                            </div>
                                        </div>

                                        <div class="mb-3">
                                            <label class="form-label" for="websiteUrl">Enter your website URL</label>
                                            <div class="position-relative auth-pass-inputgroup">
                                                <input id="websiteUrl" v-model="websiteUrl" class="form-control"
                                                    type="text" placeholder="e.g https://www.example.com"
                                                    aria-describedby="websiteUrl" required>

                                                <div class="invalid-feedback">
                                                    Please enter your website URL e.g https://www.example.com
                                                </div>
                                            </div>
                                        </div>

                                        <div class="mt-4">
                                            <button class="btn btn-success w-100" type="button"
                                                @click="onSubmit">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <!-- end card body -->
                        </div>
                        <!-- end card -->


                        <div id="errorMsg" class="card" v-show="errorMessages.length > 0">
                            <div class="card-body">
                                <div class="card-title">Account Setup Errors</div>
                                <ul class="list-group">
                                    <li v-for="error in errorMessages" :key="error.id"
                                        class="list-group-item list-group-item-danger">
                                        {{ error }}</li>
                                </ul>
                            </div>
                        </div>
                        <!--end validation error card-->
                    </div>
                </div>
                <!-- end row -->

            </div>
            <!-- end container -->

            <!--start client script container-->
            <div v-if="setupComplete === true" class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center mt-sm-5 mb-4 text-white-50">
                            <div>
                                <router-link to="/" class="d-inline-block auth-logo">
                                    <img src="@/assets/images/logo-light.png" alt="" height="20">
                                </router-link>
                            </div>
                            <p class="mt-3 fs-15 fw-medium">Low code SEO</p>
                        </div>
                    </div>
                </div>
                <!-- end row -->

                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6 col-xl-5">
                        <div class="card mt-4">

                            <div class="card-body p-4">
                                <div class="text-center mt-2">
                                    <h5 class="text-primary">Let's get started</h5>
                                </div>

                                <div>
                                    <p class=" text-break text-body">Copy the script into every page you want to rank better on.
                                    </p>
                                    <p class=" text-break text-body">No worries, if you can't add this to the page now. 
                                                You can copy it later from the dashboard</p>
                                </div>
                                <div class="p-2 mt-4">

                                    <div class="form-group">
                                        <input v-on:focus="$event.target.select()" class="form-control" ref="clone"
                                            readonly v-model="clientScript" />

                                    </div>

                                    <button class="btn btn-secondary mt-4 mb-4" style="float:right">Copy</button>

                                    <div class="mt-4">
                                        <button class="btn btn-success w-100" type="button"
                                            @click="onContinue">Continue</button>
                                    </div>
                                </div>
                            </div>
                            <!-- end card body -->
                        </div>
                        <!-- end card -->


                        <div id="errorMsg" class="card" v-show="errorMessages.length > 0">
                            <div class="card-body">
                                <div class="card-title">Account Setup Errors</div>
                                <ul class="list-group">
                                    <li v-for="error in errorMessages" :key="error.id"
                                        class="list-group-item list-group-item-danger">
                                        {{ error }}</li>
                                </ul>
                            </div>
                        </div>
                        <!--end validation error card-->
                    </div>
                </div>
                <!-- end row -->

            </div>









            <!-- end client script container-->
        </div>
        <!-- end auth page content -->

        <!-- footer -->
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center">
                            <p class="mb-0 text-muted">&copy; {{ new Date().getFullYear() }} Semantic Click.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <!-- end Footer -->
    </div>
    <!-- end auth-page-wrapper -->
</template>